import React from "react"
import Container from "components/Container"
import { Img } from "components/Tueri"
// import jsxToString from 'react-element-to-string'
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { withOrientationChange } from "react-device-detect"

import GatsbyImg from "gatsby-image"
import { Link } from "react-scroll"

import "./heading.scss"

function Heading({
  title,
  src,
  alt,
  subtitle,
  metaTitle,
  metaDescription,
  keywords,
  isPortrait,
  imageOptions,
  children,
}) {
  const { pathname } = useLocation()

  const pageTitle = metaTitle ? metaTitle : title
  const pageDescription = metaDescription ? metaDescription : subtitle

  const padding = isPortrait ? "177.5%" : "56.25%"

  const siteTitle = "Teen Ranch Canada"
  const url = "https://teenranch.com"

  if (typeof src === "string")
    return (
      <>
        <Helmet>
          <title>{`${pageTitle} - ${siteTitle}`}</title>
          <meta property="og:site_name" content="Teen Ranch" />
          <meta property="og:title" content={`${pageTitle} - ${siteTitle}`} />
          <meta
            property="twitter:title"
            content={`${pageTitle} - ${siteTitle}`}
          />
          <meta property="og:image" content={src + "?w=1200&h=1200"} />
          <meta
            property="og:image:secure_url"
            content={src + "?w=1200&h=1200"}
          />
          <meta property="og:image:alt" content={alt} />
          <meta property="twitter:image:src" content={src + "?w=1200&h=1200"} />
          <meta property="og:url" content={url + pathname} />
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta name="keywords" content={keywords} />
        </Helmet>

        <Container type="heading" constrain={false}>
          <div style={{ paddingBottom: padding, height: "0" }}>
            {src && (
              <Img
                src={src}
                alt={alt || title}
                ratio="16:9"
                portraitRatio="4:5"
                options={imageOptions}
              />
            )}
          </div>
          <div className="overlayContainer">
            <div className="overlay">
              <h1>{title}</h1>
              {subtitle && <h2>{subtitle}</h2>}
              {children}
              <div className="seeMoreContainer">
                <Link className="seeMore" to="body-start">
                  <i className="material-icons">keyboard_arrow_down</i>
                </Link>
              </div>
            </div>
          </div>
        </Container>
        <div id="body-start" style={{ position: "relative", top: "-100px" }} />
      </>
    )

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - ${siteTitle}`}</title>
        <meta property="og:site_name" content="Teen Ranch" />
        <meta property="og:title" content={`${pageTitle} - ${siteTitle}`} />
        <meta
          property="twitter:title"
          content={`${pageTitle} - ${siteTitle}`}
        />
        <meta property="og:image" content={src.src} />
        <meta property="og:image:secure_url" content={src.src} />
        <meta property="og:image:alt" content={alt} />
        <meta property="twitter:image:src" content={src.src} />
        <meta property="og:url" content={url + pathname} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
      </Helmet>

      <Container type="heading" constrain={false}>
        <div className="imageContainer">
          {src && <GatsbyImg fluid={src} alt={alt || pageTitle} />}
        </div>
        <div className="overlayContainer">
          <div className="overlay">
            <h1>{title}</h1>
            {subtitle && <h2>{subtitle}</h2>}
            {children}
            <div className="seeMoreContainer">
              <Link className="seeMore" to="body-start">
                <i className="material-icons">keyboard_arrow_down</i>
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <div id="body-start" style={{ position: "relative", top: "-100px" }} />
    </>
  )
}

export default withOrientationChange(Heading)
